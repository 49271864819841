import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import './DonationPage.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const DonationForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState('EUR');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    if (!stripe || !elements) {
      setError("Sistema de pagamento não carregado. Por favor, tente novamente.");
      setLoading(false);
      return;
    }

    if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
      setError("Por favor, insira um valor válido para a doação.");
      setLoading(false);
      return;
    }

    try {
      const { data: paymentIntentData } = await axios.post(`${process.env.REACT_APP_API_URL}/create-payment-intent`, {
        amount: Math.round(parseFloat(amount) * 100), // Convert to cents
        currency: currency
      });
      
      const result = await stripe.confirmCardPayment(paymentIntentData.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (result.error) {
        if (result.error.code === 'card_declined') {
          throw new Error('O seu cartão foi recusado. Por favor, tente um cartão diferente ou contacte o seu banco.');
        } else {
          throw result.error;
        }
      }

      const { data: confirmationData } = await axios.post(`${process.env.REACT_APP_API_URL}/confirm-payment`, {
        paymentIntentId: result.paymentIntent.id
      });

      setSuccess(true);
      setDownloadUrl(`${process.env.REACT_APP_API_URL}${confirmationData.downloadUrl}`);
    } catch (err) {
      console.error('Erro de pagamento:', err);
      setError(err.message || 'Ocorreu um erro. Por favor, tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="donation-form">
      <div className="amount-input">
        <label htmlFor="amount">Valor da doação:</label>
        <input
          type="number"
          id="amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          min="0.01"
          step="0.01"
          required
        />
        <select
          value={currency}
          onChange={(e) => setCurrency(e.target.value)}
        >
          <option value="EUR">EUR (€)</option>
          <option value="BRL">BRL (R$)</option>
        </select>
      </div>
      <div className="card-element">
        <CardElement options={{
          style: {
            base: {
              fontSize: '16px',
              color: '#424770',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#9e2146',
            },
          },
        }}/>
      </div>
      <button
        type="submit"
        disabled={!stripe || loading}
        className={`donate-button ${loading ? 'loading' : ''}`}
      >
        {loading ? 'Processando...' : 'Doar'}
      </button>
      {error && <div className="error-message">{error}</div>}
      {success && (
        <div className="success-message">
          Obrigado pela sua doação de {currency === 'EUR' ? '€' : 'R$'}{amount}! 
          {downloadUrl && (
            <a href={downloadUrl} download>Clique aqui para descarregar o seu eBook, e desejamos uma boa leitura!</a>
          )}
        </div>
      )}
    </form>
  );
};

const App = () => {
  return (
    <div className="app-container">
      <div className="content-wrapper">
        <h1 className="title">Transformar Vidas: Apoie o Retiro de Jovens</h1>
        <p className="description">
           Estamos a realizar esta campanha de angariação de fundos para apoiar o retiro de jovens da Assembleia de Deus Missão aos Povos, em Cacém, Portugal. 
           A vossa doação ajudará a proporcionar uma experiência espiritual transformadora para os nossos jovens. 
           Como forma de agradecimento pela vossa generosidade, oferecemos um eBook exclusivo após a doação. 
           A vossa contribuição é essencial para fortalecer a fé e o crescimento espiritual da nossa juventude.
        </p>
        <Elements stripe={stripePromise}>
          <DonationForm />
        </Elements>
      </div>
    </div>
  );
};

export default App;